import React, {Suspense, lazy} from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './App.css';
import $ from 'jquery';
import WooCommerce from './config/woo.config';

// components
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Chatpopup from './components/ChatPopup/Chatpopup';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

// pages
const Home = lazy(() => import ('./pages/home/home'));
const About = lazy(() => import ('./pages/about/about'));
const Help = lazy(() =>import ('./pages/help/help'));
const Products = lazy(() =>import ('./pages/products/products'));
const Detail = lazy(() =>import ('./pages/detail/detail'));
const Contact = lazy(() =>import ('./pages/contact/contact'));

class App extends React.Component {
	state = {
		categories: []
	};

	componentDidMount() {
		WooCommerce.get('products/categories').then((res) => {
			this.setState({ categories: res.data.filter((cat) => cat.name !== 'Uncategorized') });
		});
		// Some jquery for chat popup
		$('.chat-box__head').click(() => {
			$('.chat-box__body').slideToggle();
		});
	}

	render() {
		return (
			<div className="">
				<BrowserRouter basename="/">
					<ScrollToTop>
						<Navbar  categories={this.state.categories}/>

						<Suspense fallback={<div style={{width:'100%', height:'80vh'}}></div>}>
						<Switch>
							<Route exact path="/" render={() => <Home categories={this.state.categories} />} />
							<Route path="/about-us" component={About} />
							<Route
								exact
								path="/products"
								render={() => <Products categories={this.state.categories} />}
							/>
							<Route path="/products/:category/:slug" component={Detail} />
							<Route path="/help" component={Help} />
							<Route path="/contact-us" render={() => <Contact col='col-12 col-md-6' />} />
						</Switch>
						</Suspense>

						<Footer />
						<Chatpopup />
					</ScrollToTop>
				</BrowserRouter>
			</div>
		);
	}
}

export default App;
