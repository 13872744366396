import React from 'react';
import ReactWhatsapp from 'react-whatsapp';
import Button from '../Button/Button';
import axios from '../../config/config';

export default () => {
	const [ fname, setFname ] = React.useState('');
	const [ subject, setSubject ] = React.useState('');
	const [ email, setEmail ] = React.useState('');
	const [ msg, setMsg ] = React.useState('');
	const [ submit, setSubmit ] = React.useState(false);
	const [ error, setError ] = React.useState('');

	const sendMessage = (e) => {
		e.preventDefault();

		const data = {
			'your-name': fname,
			'your-email': email,
			'your-subject': subject,
			'your-message': msg
		};

		axios
			.post('/contact-form-7/v1/contact-forms/27/feedback', data)
			.then(() => {
				setSubmit(true);
				setTimeout(() => {
					setSubmit(false);
					setFname('');
					setEmail('');
					setSubject('');
					setMsg('');
				}, 3000);
			})
			.catch((error) => {
				console.log(error);
				setError('Sorry we could not send your message.');
				setSubmit(false);
			});
	};

	return (
		<div className="chat-box">
			<div className="chat-box__head">
				<strong className='text-center'>
					<i className="fas fa-whatsapp" /> <span style={{marginRight:20}}>Online</span>{' '}
					<img width={35} height={35} src={require('../../assets/images/icons/whatsapp.png')} />{' '}
				</strong>
			</div>
			<div className="chat-box__body">
				{submit && (
					<small className="text-success">Thank you for getting in touch. We will contact you soon.</small>
				)}
				<p><strong>Muzafar</strong> is here to serve you</p>
				{/* <div className="d-flex justify-content-center">
					<img width={50} height={50} src={require('../../assets/images/icons/whatsapp.png')} />
				</div> */}
				<ul className="list-group" style={{ listStyle: 'none' }}>
					<li>
						<i className="fas fa-check" /> Direct Private Message
					</li>
					<li>
						<i className="fas fa-check" /> Just One Click
					</li>
					<li>
						<i className="fas fa-check" /> Without Phone Number Save
					</li>
				</ul>
				<ReactWhatsapp className="btn btn-danger" number="+92-343-3315058">
					Start Chatting
				</ReactWhatsapp>
			</div>
		</div>
	);
};
