import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Logo from '../Logo/Logo';

const navbar = ({ location, categories }) => {
	return (
		<nav className="navbar navbar-expand-lg navbar-light bg-white p-4">
			<div className="container">
				<Link className="navbar-brand" to="/">
					<Logo />
				</Link>
				<div className="bism">
					<img className="w-100 h-100" src={require('../../assets/images/1.png')} />
				</div>
				<button
					className="navbar-toggler"
					type="button"
					data-toggle="collapse"
					data-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span className="navbar-toggler-icon" />
				</button>

				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<ul className="navbar-nav ml-auto">
						<li className={`nav-item ${location.pathname === '/about-us' && 'active'}`}>
							<Link className="nav-link" to="/about-us">
								About
							</Link>
						</li>
						<li className={`nav-item ${location.pathname === '/products' && 'active'}`}>
							<Link className="nav-link" to="/products">
								Products <i className="fas fa-caret-down" />
							</Link>
							{categories.length ? (
								<ul className="nav-dropdown">
									{categories.map((c) => (
										<Link to={`/products?filter=${c.slug}&cid=${c.id}`} key={c.id}>
											{c.name}
										</Link>
									))}
								</ul>
							) : null}
						</li>
						<li className={`nav-item`}>
							<a
								className="nav-link"
								href="https://drive.google.com/file/d/1uhhnIpr5foQ4vMZmDfwSDH9GnFgpbFbs/view?usp=sharing"
								target="_blank"
							>
								Our Catalogue
							</a>
						</li>
						<li className={`nav-item ${location.pathname === '/contact-us' && 'active'}`}>
							<Link className="nav-link" to="/contact-us">
								Contact
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
};

export default withRouter(navbar);
