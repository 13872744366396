import WooCommerceRestApi from '@woocommerce/woocommerce-rest-api';

const WooCommerce = new WooCommerceRestApi({
	url: 'https://admin.jnmsafety.com',
	consumerKey: 'ck_67f28cd13a4476a36537f8a166e023b0b85d492f',
	consumerSecret: 'cs_8b6e3be7fc544d758150a57cf81cc84ab17d9837',
	version: 'wc/v3'
});

export default WooCommerce;
