import React from 'react';
import { Link } from 'react-router-dom';
import FacebookIcon from '../../assets/images/icons/facebook.png';
import LinkedinIcon from '../../assets/images/icons/linkedin.png';
import InstaIcon from '../../assets/images/icons/instagram.png';
import TwitterIcon from '../../assets/images/icons/twitter.png';
import Logo from "../Logo/Logo";

export default () => (
	<footer className="container-fluid">
		<div className="row">
			<div className="col-12 col-md-7 d-flex">
					<Logo />
					<p className="ml-4">
					The JNM Safety is being an experienced manufacturers and exporters. We invite you to contact our
					friendly sales staff.
				</p>
			</div>
			<div className="col-12 col-md-5 d-flex justify-content-center align-items-center">
				<a href="https://www.facebook.com/jnmsafetygloves" target="_blank">
					<img src={FacebookIcon} className="mx-3 social-icon" width={50} height={50} alt="Facebook" />
				</a>
				<a href="https://www.linkedin.com/in/muzafar-hussain-706047a8" target="_blank">
					<img src={LinkedinIcon} className="mx-3 social-icon" width={50} height={50} alt="Linkedin" />
				</a>
				<a href="https://www.instagram.com/jnmsafety" target="_blank">
					<img src={InstaIcon} className="mx-3 social-icon" width={50} height={50} alt="Instagram" />
				</a>
				<a href="https://twitter.com/jnmsafety" target="_blank">
					<img src={TwitterIcon} className="mx-3 social-icon" width={50} height={50} alt="Twitter" />
				</a>
			</div>
		</div>
		<div className="row my-4">
			<div className="col-12 col-md-5 footer-links">
				<ul className="list-group list-group-horizontal">
					<li className="list-group-item bg-transparent border-0">
						<Link to="/about-us">About Us</Link>
					</li>
					<li className="list-group-item bg-transparent border-0">
						<Link to="/products">Products</Link>
					</li>
					<li className={`list-group-item bg-transparent border-0`}>
							<a
								className=""
								href="https://drive.google.com/file/d/1uhhnIpr5foQ4vMZmDfwSDH9GnFgpbFbs/view?usp=sharing"
								target="_blank"
							>
								Our Catalogue
							</a>
						</li>
					<li className="list-group-item bg-transparent border-0">
						<Link to="/contact-us">Contact Us</Link>
					</li>
				</ul>
			</div>
		</div>
		<div className="footer-divider" />

		<div className="d-flex company-address my-4">
			<p>Muzafar Hussain (Overseas Marketing Manager) JNM Leather Safety Gloves Industries </p>
			<a href="#" className="text-white">
				{' '}
				- sales@jnmsafety.com
			</a>
			<p> - +92523255057</p>
		</div>
	</footer>
);
